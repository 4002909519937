import { roundDate } from "@/utils/dates";
import { useTheme } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import {
  DataSource,
  DurationType,
  Operator,
} from "@ternary/api-lib/constants/enums";
import {
  AlertRuleEventEntity,
  CostAlertEntity,
} from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import { hasProperty } from "@ternary/api-lib/utils/typeGuards";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useGetAnomalyDetail from "../../../api/analytics/hooks/useGetAnomalyDetail";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { DateHelper } from "../../../lib/dates";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import DateRangeControls from "../../../ui-lib/components/DateRangeControls/DateRangeControls";
import Grid from "../../../ui-lib/components/Grid";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import useGetAlertRuleByID from "../hooks/useGetAlertRuleByID";
import useGetCostAlertSummary from "../hooks/useGetCostAlertSummary";
import useGetCostAlertsByAlertRuleID from "../hooks/useGetCostAlertsByAlertRuleID";
import {
  getAnomalyDateRange,
  getStringifiedCostAlertType,
  getStringifiedDelta,
  isCostAlert,
  isRuleCreatedEvent,
  populateCostAlert,
} from "../utils";
import AlertDetailModal from "./AlertDetailModal";
import AlertFeedMeters from "./AlertFeedMeters";
import RuleDetailModal from "./RuleDetailModal";

const MODAL_ALERT_EVENT = "MODAL_ALERT_EVENT";
const MODAL_RULE_EVENT = "MODAL_RULE_EVENT";

type Interaction = AlertDetailModal.Interaction;

interface State {
  dateRange: Date[];
  duration: DurationType;
  modalKey?: string;
  selectedCostAlertID?: string;
  selectedRuleEventID?: string;
}

const initialState = {
  dateRange: [],
  duration: DurationType.LAST_THIRTY_DAYS,
  modalKey: undefined,
  selectedCostAlertID: undefined,
  selectedRuleEventID: undefined,
};

export function AlertFeedContainer(): JSX.Element {
  const theme = useTheme();
  const authenticatedUser = useAuthenticatedUser();
  const navigate = useNavigateWithSearchParams();
  const activityTracker = useActivityTracker();

  const { alertRuleID = "" } = useParams();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _alertRule, isLoading: isLoadingAlertRule } =
    useGetAlertRuleByID(alertRuleID, { includeEvents: true });

  const alertRule = _alertRule;

  const alertRuleEvents = alertRule?.events ?? [];

  const { data: costAlerts = [], isLoading: isLoadingCostAlerts } =
    useGetCostAlertsByAlertRuleID(alertRuleID);

  const selectedCostAlert = costAlerts.find(
    (rule) => rule.id === state.selectedCostAlertID
  );

  const populatedCostAlert = selectedCostAlert
    ? populateCostAlert(selectedCostAlert, alertRule)
    : undefined;

  const ruleFilters: QueryFilter[] =
    populatedCostAlert?.sourceAlertRule.filters ?? [];

  const alertFilters: QueryFilter[] = useMemo(() => {
    if (!selectedCostAlert) return [];
    if (!isCostAlert(selectedCostAlert)) return [];

    // If value is null operator should be NOT_SET
    return selectedCostAlert.dimensions.map((dimension) => {
      return {
        name: dimension.key,
        operator: dimension.value ? Operator.EQUALS : Operator.NOT_SET,
        values: dimension.value ? [dimension.value] : null,
      };
    });
  }, [selectedCostAlert]);

  const anomalyDateRange = useMemo(() => {
    if (!populatedCostAlert) return [];

    return getAnomalyDateRange(
      populatedCostAlert.eventTime,
      populatedCostAlert.sourceAlertRule.timeGranularity
    );
  }, [selectedCostAlert]);

  const { data: alertCostData = [], isLoading: isLoadingAlertCostData } =
    useGetAnomalyDetail(
      {
        dataSource: DataSource.BILLING,
        dateRange: anomalyDateRange,
        granularity: populatedCostAlert?.sourceAlertRule.timeGranularity,
        queryFilters: [...ruleFilters, ...alertFilters],
      },
      {
        enabled:
          state.modalKey === MODAL_ALERT_EVENT &&
          !!selectedCostAlert &&
          anomalyDateRange.length === 2,
      }
    );

  const dateRange =
    state.dateRange[0] && state.dateRange[1]
      ? state.dateRange
      : [
          getCubeDateRangeFromDurationType(state.duration)[0],
          roundDate(new Date()),
        ];

  const { data: alertSummary, isLoading: isLoadingAnomalySummary } =
    useGetCostAlertSummary({
      dateRange: dateRange,
      filters: [
        {
          name: "alertRuleID",
          operator: Operator.EQUALS,
          values: [alertRuleID],
        },
      ],
    });

  const { data: labelMaps } = useGetLabelMapsByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  //
  // Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case AlertDetailModal.INTERACTION_INVESTIGATE_CLICKED: {
        activityTracker.captureAction(
          actions.SELECT_ALERT_TO_INVESTIGATE_IN_TRE,
          {
            value: state.selectedCostAlertID,
          }
        );

        navigate(paths._reportBuilderNew, {
          state: {
            runQueryTriggered: true,
            report: {
              dataSource: DataSource.BILLING,
              dimensions: interaction.dimensions,
              durationType: DurationType.CUSTOM,
              endDate: interaction.endDate,
              filters: interaction.filters,
              measures: interaction.measures,
              startDate: interaction.startDate,
            },
          },
        });
        break;
      }
    }
  }
  //
  // Render
  //

  const sortedAlerts = getGroupedAndSortedEvents({
    costAlerts,
    alertRuleEvents,
  });

  function renderCostAlert(params: {
    alert: CostAlertEntity;
    index: number;
  }): JSX.Element {
    const { alert, index } = params;

    return (
      <Grid
        color="black"
        display="grid"
        gridTemplateColumns="1fr 3px 1fr"
        margin="0 auto"
        width="800px"
      >
        <Flex justifyContent="flex-end">
          <Box
            backgroundColor={theme.modal_background_color}
            backgroundColorOnHover={theme.secondary_color_background}
            borderRadius="10px"
            boxShadow="0 0 2px rgba(0, 0, 0, 0.1)"
            cursor="pointer"
            display="inline-block"
            margin="1em"
            minWidth="250px"
            padding="0.5em 1em"
            onClick={() => {
              mergeState({
                modalKey: MODAL_ALERT_EVENT,
                selectedCostAlertID: alert.id,
              });
            }}
          >
            <Text>{getStringifiedCostAlertType(alert.eventType)}</Text>
            <Flex alignItems="center" justifyContent="space-between">
              <Text appearance="h4" bold>
                {formatCurrency({
                  number: alert.eventValue,
                })}
              </Text>
              <Text color={theme.feedback_negative} marginLeft={theme.space_sm}>
                ({getStringifiedDelta(alert.eventValue, alert.expectedValue)})
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          backgroundColor={theme.alert_event_feed_timeline}
          display="flex"
          position="relative"
        >
          <Flex
            backgroundColor={theme.alert_event_feed_alert_event_node}
            borderRadius="50%"
            height="15px"
            left="50%"
            position="absolute"
            transform="translateX(-50%)"
            width="15px"
          />
        </Flex>
        <Flex
          alignItems="center"
          alignSelf="left"
          margin="20px"
          marginLeft="20px"
          marginRight="auto"
        >
          <Text>
            {index === 0
              ? new Date(new Date(alert.createdAt)).toLocaleDateString(
                  undefined,
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )
              : new Date(new Date(alert.createdAt))
                  .toLocaleDateString(undefined, {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                  .split(", ")[1]}
          </Text>
        </Flex>
      </Grid>
    );
  }

  function renderAlertRuleEvent(params: {
    event: AlertRuleEventEntity;
    index: number;
  }) {
    const { event, index } = params;

    if (!hasConfigurationUpdates(event.data)) {
      return null;
    }

    return (
      <Grid
        color="black"
        display="grid"
        gridTemplateColumns="1fr 3px 1fr"
        margin="0 auto"
        width="800px"
      >
        <Flex
          alignItems="center"
          alignSelf="right"
          margin="20px"
          marginLeft="auto"
          marginRight="20"
        >
          <Text>
            {index === 0
              ? new Date(new Date(event.createdAt)).toLocaleDateString(
                  undefined,
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )
              : new Date(new Date(event.createdAt))
                  .toLocaleDateString(undefined, {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                  .split(", ")[1]}
          </Text>
        </Flex>
        {isRuleCreatedEvent(event) ? (
          <Flex
            alignItems="end"
            backgroundColor={theme.alert_event_feed_timeline}
            display="flex"
            height="50%"
            position="relative"
          >
            <Flex
              backgroundColor={theme.alert_event_feed_rule_event_node}
              borderRadius="50%"
              height="20px"
              left="50%"
              position="absolute"
              transform="translateX(-50%)"
              width="20px"
            />
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            backgroundColor={theme.alert_event_feed_timeline}
            display="flex"
            position="relative"
          >
            <Flex
              backgroundColor={theme.alert_event_feed_rule_event_node}
              borderRadius="50%"
              height="20px"
              left="50%"
              position="absolute"
              transform="translateX(-50%)"
              width="20px"
            />
          </Flex>
        )}
        <Flex justifyContent="flex-start">
          <Box
            backgroundColor={theme.modal_background_color}
            backgroundColorOnHover={theme.secondary_color_background}
            borderRadius="10px"
            boxShadow="0 0 2px rgba(0, 0, 0, 0.1)"
            cursor="pointer"
            display="inline-block"
            margin="1em"
            minWidth="250px"
            padding="0.5em 1em"
            onClick={() => {
              mergeState({
                modalKey: MODAL_RULE_EVENT,
                selectedRuleEventID: event.id,
              });
            }}
          >
            {isRuleCreatedEvent(event) ? (
              <Flex alignItems="center">
                <Text>{copyText.eventStreamRuleCreatedTitle}</Text>
              </Flex>
            ) : (
              <Flex alignItems="center">
                <Text>{copyText.eventStreamRuleUpdatedTitle}</Text>
                <Text marginLeft={theme.space_sm}>
                  ({Object.entries(event.data).length})
                </Text>
              </Flex>
            )}
          </Box>
        </Flex>
      </Grid>
    );
  }

  const selectedRuleEvent = alertRuleEvents.find(
    (rule) => rule.id === state.selectedRuleEventID
  );

  function renderModal() {
    if (!state.modalKey) {
      return null;
    }

    switch (state.modalKey) {
      case MODAL_RULE_EVENT: {
        if (!selectedRuleEvent) return null;

        return (
          <RuleDetailModal
            isOpen={true}
            event={selectedRuleEvent}
            onClose={() => {
              mergeState({
                modalKey: undefined,
                selectedRuleEventID: undefined,
              });
            }}
          ></RuleDetailModal>
        );
      }
      default: {
        activityTracker.captureAction(actions.SELECT_ALERT_EVENT_TO_DISPLAY);

        if (!populatedCostAlert) return null;

        return (
          <AlertDetailModal
            costData={alertCostData}
            alert={populatedCostAlert}
            isLoading={isLoadingAlertCostData}
            isOpen={true}
            labelMaps={labelMaps}
            onClose={() => {
              mergeState({
                modalKey: undefined,
                selectedCostAlertID: undefined,
              });
            }}
            onInteraction={handleInteraction}
          />
        );
      }
    }
  }

  //
  // JSX
  //

  return (
    <Box>
      <Flex alignItems="center" height={60}>
        <Button
          iconStart={<Icon icon={faChevronLeft} />}
          marginRight={theme.space_md}
          marginTop={theme.space_xxs}
          secondary
          size="small"
          onClick={() =>
            navigate(paths._alertTracking, {
              searchParams: { tab: "alertRules" },
            })
          }
        />
        <Text appearance="h3">{alertRule?.name ?? ""}</Text>
      </Flex>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginVertical={theme.space_md}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={state.duration}
          hiddenOptions={[DurationType.QUARTER_TO_DATE]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            mergeState({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    dateRange: newDateRange,
                  }
                : {
                    dateRange: [],
                  }),
            });
          }}
        />
      </Flex>
      <AlertFeedMeters
        isLoading={isLoadingAnomalySummary}
        negativeDelta={alertSummary?.negativeDelta}
        percentageAnomalous={alertSummary?.percentageAnomalous}
        positiveDelta={alertSummary?.positiveDelta}
        totalCostAlerts={alertSummary?.totalAlerts}
      />
      {isLoadingCostAlerts ||
      isLoadingAlertRule ||
      !alertRule ||
      isLoadingAlertRule ? (
        <EmptyPlaceholder
          loading={isLoadingCostAlerts || isLoadingAlertRule}
          skeletonVariant="rows"
        ></EmptyPlaceholder>
      ) : (
        sortedAlerts.map((groupedEvents) => {
          return groupedEvents[1].map((event, index) => {
            return (
              <Box key={index}>
                {isCostAlert(event)
                  ? renderCostAlert({
                      alert: event,
                      index: index,
                    })
                  : renderAlertRuleEvent({
                      event: event,
                      index: index,
                    })}
              </Box>
            );
          });
        })
      )}
      {renderModal()}
    </Box>
  );
}

export default AlertFeedContainer;

// Group events into day created and type
function getGroupedAndSortedEvents(params: {
  costAlerts: CostAlertEntity[];
  alertRuleEvents: AlertRuleEventEntity[];
}): [string, (CostAlertEntity | AlertRuleEventEntity)[]][] {
  let groupedEntities: {
    [key: string]: (CostAlertEntity | AlertRuleEventEntity)[];
  } = {};

  const sortedEntities = [...params.alertRuleEvents, ...params.costAlerts].sort(
    (a, b) => {
      return (
        new Date(Date.parse(b.createdAt)).getTime() -
        new Date(Date.parse(a.createdAt)).getTime()
      );
    }
  );

  groupedEntities = sortedEntities.reduce((accum, event) => {
    const eventType = isCostAlert(event)
      ? "ALERT"
      : isRuleCreatedEvent(event)
        ? "CREATE"
        : "UPDATE";

    const key = `${new Date(
      event.createdAt
    ).toDateString()}%DELIM%${eventType} `;

    if (accum[key]) {
      accum[key].push(event);
    } else {
      accum[key] = [event];
    }
    return accum;
  }, groupedEntities);

  return Object.entries(groupedEntities).sort((a, b) => {
    const aDate = a[0].split("%DELIM%")[0];
    const bDate = b[0].split("%DELIM%")[0];
    return (
      new Date(Date.parse(bDate)).getTime() -
      new Date(Date.parse(aDate)).getTime()
    );
  });
}

function hasConfigurationUpdates(
  eventData: AlertRuleEventEntity["data"]
): boolean {
  return (
    hasProperty(eventData, "condition") ||
    hasProperty(eventData, "dataSource") ||
    hasProperty(eventData, "dimensions") ||
    hasProperty(eventData, "filters") ||
    hasProperty(eventData, "measure") ||
    hasProperty(eventData, "status") ||
    hasProperty(eventData, "timeGranularity")
  );
}
