import copyText from "../../copyText";
import { FormData } from "./types";

export const BigQueryScope = {
  JOBS_BY_FOLDER: "JOBS_BY_FOLDER",
  JOBS_BY_PROJECT: "JOBS_BY_PROJECT",
};

export const defaultValues: FormData = {
  // General
  billingAccountID: "",
  enableCommitmentSharing: false,
  name: "",
  rootResourceID: "",

  // Standard Billing
  datasetIDStandard: "",
  locationStandard: "US",
  projectIDStandard: "",
  tableIDStandard: "",

  // Detailed Billing
  datasetIDDetailed: "",
  locationDetailed: "US",
  projectIDDetailed: "",
  tableIDDetailed: "",

  // Custom Pricing
  datasetIDCustom: "",
  locationCustom: "US",
  projectIDCustom: "",
  tableIDCustom: "",

  // Carbon Footprint
  datasetIDCarbon: "",
  locationCarbon: "US",
  projectIDCarbon: "",
  tableIDCarbon: "",

  // BigQuery
  configsBigQuery: [],
  regionsBigQuery: [],
  projectIDBigQuery: "",
  scopeBigQuery: BigQueryScope.JOBS_BY_PROJECT,
};

// https://cloud.google.com/bigquery/docs/locations#locations_and_regions
export const bigQueryLocations = [
  // Multi-regions
  "US",
  "EU",

  // Single zones
  "asia-east1",
  "asia-east2",
  "asia-northeast1",
  "asia-northeast2",
  "asia-northeast3",
  "asia-south1",
  "asia-south2",
  "asia-southeast1",
  "asia-southeast2",
  "australia-southeast1",
  "australia-southeast2",
  "europe-central2",
  "europe-north1",
  "europe-southwest1",
  "europe-west1",
  "europe-west12",
  "europe-west2",
  "europe-west3",
  "europe-west4",
  "europe-west6",
  "europe-west8",
  "europe-west9",
  "me-central1",
  "me-central2",
  "me-west1",
  "northamerica-northeast1",
  "northamerica-northeast2",
  "southamerica-east1",
  "southamerica-west1",
  "us-central1",
  "us-east1",
  "us-east4",
  "us-east5",
  "us-south1",
  "us-west1",
  "us-west2",
  "us-west3",
  "us-west4",
];

// Conventionally, a billing account ID is 18 uppercase hexadecimal digits,
// in groups of 6, separated by hyphens.
export const BILLING_ACCOUNT_ID_REGEX = /[A-F0-9]{6}-[A-F0-9]{6}-[A-F0-9]{6}/;

// The dataset name can contain the following:
// * Letters (uppercase or lowercase), numbers, and underscores."
// https://cloud.google.com/bigquery/docs/datasets#dataset-naming
// https://cloud.google.com/bigquery/docs/tables#table_naming
export const BQ_FIELDS_REGEX = /^[a-zA-Z0-9_]+$/;

// Root element reference.
export const RESOURCE_ID_REGEX = /(organizations|projects|folders)\/[0-9]+/;

// "The project ID must be a unique string of 6 to 30 lowercase letters, digits, or
// hyphens. It must start with a letter, and cannot have a trailing hyphen. You
// cannot change a project ID once it has been created.
// https://cloud.google.com/resource-manager/docs/creating-managing-projects
export const PROJECT_ID_REGEX = /[a-z][a-z-0-9]{4,28}[a-z0-9]/;

export const validateDatasetID = (value: string) =>
  !BQ_FIELDS_REGEX.test(value)
    ? copyText.gcpFormDatasetIDValidationErrorCaption
    : undefined;

export const validateProjectID = (value: string) =>
  !PROJECT_ID_REGEX.test(value)
    ? copyText.gcpFormProjectIDValidationErrorCaption
    : undefined;

export const validateTableID = (value: string) =>
  !BQ_FIELDS_REGEX.test(value)
    ? copyText.gcpFormTableIDValidationErrorCaption
    : undefined;
