import common from "../../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  addBigQueryConfigurationButtonLabel: "Add Configuration",
  backToIntegrationsButtonLabel: "Back to Integrations",
  errorCreatingGcpIntegrationMessage: "An error occurred while attempting to create the GCP data integration. Please try again.",
  errorUpdatingGcpIntegrationMessage: "An error occurred while attempting to update the GCP data integration. Please try again.",
  errorValidatingDataIntegrationMessage: "An error occurred while attempting to validate the data integration. Please try again.",
  gcpFormBigQueryConfigurationsListLabel: "Configurations:",
  gcpFormBigQueryProjectIDInputLabel: "Project ID",
  gcpFormBigQueryScope: "Scan for BigQuery Jobs",
  gcpFormBigQueryScopeFolderLabel: "In the folder containing this project",
  gcpFormBigQueryScopeProjectLabel: "In this project only",
  gcpFormBigQueryUsageRegions: "Usage Regions",
  gcpFormBillingDatasetIDInputCaption: "Dataset ID containing the Standard Billing export.",
  gcpFormBillingDatasetIDInputLabel: "Dataset ID",
  gcpFormBillingLocationInputCaption: "Location of the Standard Billing export.",
  gcpFormBillingLocationInputLabel: "Location",
  gcpFormBillingProjectIDInputCaption: "Project ID containing the Standard Billing export.",
  gcpFormBillingProjectIDInputLabel: "Project ID",
  gcpFormBillingTableIDInputCaption: "Table ID containing the Standard Billing export.",
  gcpFormBillingTableIDInputLabel: "Table ID",
  gcpFormCarbonFootprintDatasetIDInputCaption: "Dataset ID containing the Carbon Footprint export.",
  gcpFormCarbonFootprintLocationInputCaption: "Location of the Carbon Footprint export.",
  gcpFormCarbonFootprintProjectIDInputCaption: "Project ID containing the Carbon Footprint export.",
  gcpFormCarbonFootprintTableIDInputCaption: "Table ID containing the Carbon Footprint export.",
  gcpFormCustomPricingDatasetIDInputCaption: "Dataset ID containing the Custom Pricing export.",
  gcpFormCustomPricingLocationInputCaption: "Location of the Custom Pricing export.",
  gcpFormCustomPricingProjectIDInputCaption: "Project ID containing the Custom Pricing export.",
  gcpFormCustomPricingTableIDInputCaption: "Table ID containing the Custom Pricing export.",
  gcpFormDetailedBillingDatasetIDInputCaption: "Dataset ID containing the Detailed Billing export.",
  gcpFormDetailedBillingLocationInputCaption: "Location of the Detailed Billing export.",
  gcpFormDetailedBillingProjectIDInputCaption: "Project ID containing the Detailed Billing export.",
  gcpFormDetailedBillingTableIDInputCaption: "Table ID containing the Detailed Billing export.",
  gcpFormGeneralBillingAccountIDInputCaption: "18 letter/digit billing account ID from your Google Cloud Billing console.",
  gcpFormGeneralBillingAccountIDInputLabel: "Billing Account ID",
  gcpFormGeneralBillingAccountIDInputPlaceholder: "AAA000-BBB111-CCC222",
  gcpFormGeneralCudSharingEnabledLabel: "Commitment Sharing Enabled",
  gcpFormGeneralNameInputLabel: "Name",
  gcpFormGeneralRootResourceIDInputCaption: "Designate an organization, folder or single project that Ternary will scan. For best results, use an organization.",
  gcpFormGeneralRootResourceIDInputLabel: "Root Resource ID",
  gcpFormGeneralRootResourceIDInputPlaceholder: "organizations/111222333",
  gcpFormNewGcpIntegrationHeader: "New GCP Integration",
  gcpFormDatasetIDValidationErrorCaption: "Must be a GCP dataset ID",
  gcpFormProjectIDValidationErrorCaption: "Must be a GCP project ID",
  gcpFormTableIDValidationErrorCaption: "Must be a GCP table ID",
  gcpFormTabLabelBigQuery: "BigQuery",
  gcpFormTabLabelCarbonFootprint: "Carbon Footprint Export",
  gcpFormTabLabelCustomPricing: "Pricing Export",
  gcpFormTabLabelDetailedBilling: "Detailed Export",
  gcpFormTabLabelGeneral: "General",
  gcpFormTabLabelStandardBilling: "Standard Export",
  successCreatingGcpIntegrationMessage: "The GCP data integration has been successfully created.",
  successUpdatingGcpIntegrationMessage: "The GCP data integration has been successfully updated.",
};
