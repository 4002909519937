import useGetUsersByTenantID from "@/api/core/hooks/useGetUsersByTenantID";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faFileExport,
  faGear,
  faLock,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import BarLoader from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder/BarLoader";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import {
  convertSnakeCaseToTitleCase,
  convertTitleCaseToSnakeCase,
} from "@ternary/api-lib/utils/CaseUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import { isEmpty, isNil, keyBy } from "lodash";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import { AuthenticatedUserEntity } from "../../../api/core/types";
import useGetTenantByID from "../../../hooks/useGetTenantByID";
import useUpdateTenant from "../../../hooks/useUpdateTenant";
import TextInput from "../../../ui-lib/components/TextInput";
import { getFullName } from "../../../utils/UserUtils";
import UserForm from "../components/UserForm";
import UserTable, { User } from "../components/UserTable";
import copyText from "../copyText";
import useGetGrantsByTenantID from "../hooks/useGetGrantsByTenantID";
import useGetRolesByTenantID from "../hooks/useGetRolesByTenantID";
import useGrantUsersTenantAccess from "../hooks/useGrantUsersTenantAccess";
import useRevokeUserTenantAccess from "../hooks/useRevokeUserTenantAccess";
import useUpdateUserTenantRoles from "../hooks/useUpdateUserTenantRoles";
import UserNotificationPreferences from "./UserNotificationPreferences";

type Interaction =
  | UserForm.Interaction
  | UserNotificationPreferences.Interaction
  | UserTable.Interaction;

type UserCSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

interface State {
  selectedUserID: string | null;
  showForm: boolean;
  showModal: boolean;
  showPreferenceForm: boolean;
  searchText: string;
}

const initialState: State = {
  selectedUserID: null,
  showForm: false,
  showModal: false,
  showPreferenceForm: false,
  searchText: "",
};

export default function UserManagementContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const gatekeeper = useGatekeeper();

  const { tenantID } = useParams();

  // If coming from internal page there will be a tenantID in the query params.
  // Otherwise use the tenantID on authenticatedUser.
  const actingTenantID = tenantID ? tenantID : authenticatedUser.tenant.id;

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  // Need the selected tenant for display purposes here.
  const { data: tenant, isLoading: isLoadingTenant } = useGetTenantByID(
    tenantID ?? "",
    {
      enabled:
        (gatekeeper.canReadTenantsSystem || gatekeeper.canReadTenantsPartner) &&
        !!tenantID,
    }
  );

  const canListUsersAndGrants =
    gatekeeper.canListUsers && gatekeeper.canListGrants;

  const {
    data: _users,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useGetUsersByTenantID(actingTenantID, {
    enabled: canListUsersAndGrants,
  });

  const {
    data: grants = [],
    isLoading: isLoadingGrants,
    refetch: refetchGrants,
  } = useGetGrantsByTenantID(actingTenantID, {
    enabled: canListUsersAndGrants,
  });

  const { data: _roles = [], isLoading: isLoadingRoles } =
    useGetRolesByTenantID(actingTenantID, { enabled: true });

  //
  // Mutations
  //

  const {
    isPending: isGrantingUsersTenantAccess,
    mutate: grantUsersTenantAccess,
  } = useGrantUsersTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUsersTenantAccessMessage,
      });
    },
    onSuccess: () => {
      mergeState({ showForm: false });

      refetchUsers();
      refetchGrants();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successGrantingUsersTenantAccessMessage,
      });
    },
  });

  const {
    isPending: isRevokingUserTenantAccess,
    mutate: revokeUserTenantAccess,
  } = useRevokeUserTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorRevokingUserTenantAccessMessage,
      });
    },
    onSuccess: () => {
      mergeState({ showModal: false, selectedUserID: null });

      refetchUsers();
      refetchGrants();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successRevokingUserTenantAccessMessage,
      });
    },
  });

  const {
    isPending: isUpdatingUserTenantRoles,
    mutate: updateUserTenantRoles,
  } = useUpdateUserTenantRoles({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorUpdatingUserTenantRoles,
      });
    },
    onSuccess: () => {
      mergeState({ showForm: false, selectedUserID: null });

      refetchGrants();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingUserTenantRoles,
      });
    },
  });

  const { isPending: isLoadingNotificationPreferences, mutate: updateTenant } =
    useUpdateTenant({
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorUpdatingUserNotificationMessage,
        });
      },
      onSuccess: (_, params) => {
        queryClient.setQueryData<AuthenticatedUserEntity>(
          ["authenticatedUser"],
          (authenticatedUser) => {
            if (!authenticatedUser) return;

            const updatedTenant: AuthenticatedUserEntity["tenant"] = {
              ...authenticatedUser.tenant,
              newUserNotificationSettings: params.newUserNotificationSettings
                ? params.newUserNotificationSettings
                : {
                    notifyAlerts: false,
                    notifyBudgets: false,
                    notifyRecommendations: false,
                    notifyReportsDaily: false,
                    notifyReportsWeekly: false,
                    notifyReportsMonthly: false,
                  },
            };

            return {
              ...authenticatedUser,
              tenant: updatedTenant,
            };
          }
        );

        mergeState({ showPreferenceForm: false });

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successUpdatingUserNotifications,
        });
      },
    });

  //
  // Interaction Handlers
  //

  const usersKeyedByEmail = keyBy(_users, "email");

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case UserForm.INTEGRATION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedUserID: null, showForm: false });
        return;
      }
      case UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_CREATE: {
        const users = interaction.params.map((params) => {
          const user = usersKeyedByEmail[params.email];

          return {
            ...params,
            ...(user ? { userID: user.id } : {}),
          };
        });

        grantUsersTenantAccess({
          tenantID: actingTenantID,
          users,
          ...(interaction.notificationPreferences
            ? { userNotifications: interaction.notificationPreferences }
            : {}),
        });

        return;
      }
      case UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        const rolesKeyedByID = keyBy(_roles, "id");

        const roleNames = interaction.roleIDs.map(
          (roleID) => rolesKeyedByID[roleID].name
        );

        const roleSlugs = roleNames.map(convertTitleCaseToSnakeCase);

        updateUserTenantRoles({
          grantID: `${actingTenantID}:${interaction.userID}`,
          roles: roleSlugs,
        });
        return;
      }
      case UserNotificationPreferences.INTERACTION_CLOSE_BUTTON_CLICKED: {
        mergeState({ showPreferenceForm: false });
        return;
      }
      case UserNotificationPreferences.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateTenant({
          tenantID: actingTenantID,
          newUserNotificationSettings: interaction.notificationConfig,
        });
        return;
      }
      case UserTable.INTERACTION_REVOKE_USER_ACCESS_CLICKED: {
        mergeState({ selectedUserID: interaction.userID, showModal: true });
        return;
      }
      case UserTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED: {
        mergeState({ selectedUserID: interaction.userID, showForm: true });
        return;
      }
    }
  }

  function handleRevokeUserTenantAccess(userID: string) {
    revokeUserTenantAccess({
      grantID: `${actingTenantID}:${userID}`,
    });

    // If a user removes their own Tenant access, force reload the app with no search params to kick them out of the Tenant
    if (!tenantID && authenticatedUser.id === userID) {
      window.location.href = window.location.origin;
    }
  }

  //
  // Render
  //

  if (!canListUsersAndGrants) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const isLoading =
    isLoadingUsers || isLoadingGrants || isLoadingTenant || isLoadingRoles;

  const grantsKeyedByUserID = keyBy(grants, "userID");

  const users = useMemo(() => {
    if (!_users) return [];

    return _users.reduce((accum: User[], user) => {
      const grant = grantsKeyedByUserID[user.id];

      if (!grant) return accum;

      return [...accum, { ...user, grant }];
    }, []);
  }, [_users, grants]);

  const selectedUser = users.find((user) => user.id === state.selectedUserID);

  let filteredUsers = users;

  const searchStr = state.searchText.toLowerCase();

  if (state.searchText.length > 0) {
    filteredUsers = users.filter((user) => {
      const name = getFullName(user).toLowerCase();
      const email = user.email.toLowerCase();
      return name.includes(searchStr) || email.includes(searchStr);
    });
  }

  // Don't allow users to assign global roles at the tenant level.
  const roles = _roles.filter((role) => !role.visibilityPermission);

  const csvData = useMemo(() => getCSVData(users), [users]);

  return (
    <>
      {state.showModal && selectedUser && (
        <ConfirmationModal
          isLoading={isRevokingUserTenantAccess}
          message={copyText.revokerUserAccessConfirmationMessage.replace(
            "%name%",
            getFullName(selectedUser)
          )}
          title={copyText.revokerUserAccessConfirmationTitle}
          variant="danger"
          onConfirm={() => handleRevokeUserTenantAccess(selectedUser.id)}
          onCancel={() =>
            mergeState({ selectedUserID: null, showModal: false })
          }
        />
      )}
      {state.showForm && (
        <UserForm
          isOpen
          isProcessing={
            isGrantingUsersTenantAccess || isUpdatingUserTenantRoles
          }
          roles={roles}
          selectedUser={selectedUser}
          users={users}
          onInteraction={handleInteraction}
        />
      )}
      {state.showPreferenceForm && (
        <UserNotificationPreferences
          isOpen
          isLoading={isLoadingNotificationPreferences}
          userNotifications={
            authenticatedUser.tenant.newUserNotificationSettings
          }
          onInteraction={handleInteraction}
        />
      )}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginVertical={theme.space_md}
      >
        <Flex alignItems="center">
          {tenantID && (
            <>
              {isLoadingTenant ? (
                <BarLoader height={32} width={100} />
              ) : (
                <Text fontSize={theme.h4_fontSize}>{tenant?.name}</Text>
              )}
              <Text marginHorizontal={theme.space_sm}>{">"}</Text>
            </>
          )}
          <Text
            fontSize={theme.h4_fontSize}
          >{`${copyText.userCountLabel} (${users.length})`}</Text>
        </Flex>
        <Flex alignItems="center">
          <Box width={300} marginRight={theme.space_lg}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="medium"
              value={state.searchText}
              onChange={(e) => mergeState({ searchText: e.target.value })}
            />
          </Box>
          <Box marginRight={theme.space_md}>
            <CSVLink
              data={csvData.rows}
              headers={csvData.headers}
              filename={`users-${format(new Date(), "MM-dd-yyyy")}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                secondary
                size="small"
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Box>
          <Button
            iconStart={<Icon icon={faPlus} />}
            locked={!gatekeeper.canGrantUsersTenantAccess}
            primary
            size="small"
            onClick={() => mergeState({ showForm: true })}
          >
            {copyText.addUsersButtonLabel}
          </Button>

          <Tooltip content={copyText.newUserNotificationsToolTipMessage}>
            <Button
              iconEnd={<Icon icon={faGear} />}
              marginLeft={theme.space_md}
              secondary
              size="small"
              onClick={() => mergeState({ showPreferenceForm: true })}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <UserTable
        isLoading={isLoading}
        users={filteredUsers}
        onInteraction={handleInteraction}
      />
    </>
  );
}

const csvAccessors = [
  "name",
  "email",
  "roles",
  "createdAt",
  "lastLogin",
] as const;

function getCSVData(users: User[]): UserCSVData {
  if (!users.length) {
    return { headers: [], rows: [] };
  }
  const rows = users.map((user) => {
    const fullName = getFullName(user);

    return {
      name: isEmpty(fullName.trim()) ? user.email : fullName,
      createdAt: formatDate(new Date(user.createdAt), "MM/dd/yyyy"),
      email: user.email,
      lastLogin: isNil(user.lastLogin)
        ? copyText.notAvailable
        : new Date(user.lastLogin).toLocaleString(),
      roles: user.grant.roles
        .map((role) => convertSnakeCaseToTitleCase(role))
        .join(", "),
    };
  });

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `userTableHeader_${csvAccessor}`;

    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}
