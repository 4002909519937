import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import React from "react";
import { FormField } from "../../../../../ui-lib/components/Form";
import Select from "../../../../../ui-lib/components/Select";
import TextInput from "../../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import {
  bigQueryLocations,
  validateDatasetID,
  validateProjectID,
  validateTableID,
} from "./constants";
import { FormProps } from "./types";

const validators = {
  datasetID: ({ value }: { value: string }) => {
    if (value.length === 0) return;

    return validateDatasetID(value);
  },
  projectID: ({ value }: { value: string }) => {
    if (value.length === 0) return;

    return validateProjectID(value);
  },
  tableID: ({ value }: { value: string }) => {
    if (value.length === 0) return;

    return validateTableID(value);
  },
};

const locationOptions = bigQueryLocations.map((location) => ({
  label: location,
  value: location,
}));

export function GcpIntegrationFormCarbonFootprint(props: FormProps) {
  const theme = useTheme();

  const { form } = props;

  return (
    <Box padding={theme.space_xxs}>
      <Flex gap={theme.space_md}>
        <Flex flex="1 1 0">
          <form.Field
            name="projectIDCarbon"
            validators={{
              onBlur: validators.projectID,
            }}
            children={(field) => {
              const shouldShowError =
                field.state.value.length > 0 &&
                field.state.meta.isTouched &&
                field.state.meta.errors.length > 0;

              return (
                <FormField
                  input={TextInput}
                  caption={copyText.gcpFormCarbonFootprintProjectIDInputCaption}
                  errorCaption={
                    shouldShowError
                      ? field.state.meta.errors.join(", ")
                      : undefined
                  }
                  label={copyText.gcpFormBillingProjectIDInputLabel}
                  value={field.state.value}
                  variant={shouldShowError ? "danger" : undefined}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              );
            }}
          />
        </Flex>
        <Flex flex="1 1 0">
          <form.Field
            name="datasetIDCarbon"
            validators={{
              onBlur: validators.datasetID,
            }}
            children={(field) => {
              const shouldShowError =
                field.state.value.length > 0 &&
                field.state.meta.isTouched &&
                field.state.meta.errors.length > 0;

              return (
                <FormField
                  input={TextInput}
                  caption={copyText.gcpFormCarbonFootprintDatasetIDInputCaption}
                  errorCaption={
                    shouldShowError
                      ? field.state.meta.errors.join(", ")
                      : undefined
                  }
                  label={copyText.gcpFormBillingDatasetIDInputLabel}
                  value={field.state.value}
                  variant={shouldShowError ? "danger" : undefined}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              );
            }}
          />
        </Flex>
      </Flex>
      <Flex gap={theme.space_md}>
        <Flex flex="1 1 0">
          <form.Field
            name="tableIDCarbon"
            validators={{
              onBlur: validators.tableID,
            }}
            children={(field) => {
              const shouldShowError =
                field.state.value.length > 0 &&
                field.state.meta.isTouched &&
                field.state.meta.errors.length > 0;

              return (
                <FormField
                  input={TextInput}
                  caption={copyText.gcpFormCarbonFootprintTableIDInputCaption}
                  errorCaption={
                    shouldShowError
                      ? field.state.meta.errors.join(", ")
                      : undefined
                  }
                  label={copyText.gcpFormBillingTableIDInputLabel}
                  value={field.state.value}
                  variant={shouldShowError ? "danger" : undefined}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              );
            }}
          />
        </Flex>
        <Flex flex="1 1 0">
          <form.Field
            name="locationCarbon"
            children={(field) => {
              return (
                <FormField
                  caption={copyText.gcpFormCarbonFootprintLocationInputCaption}
                  label={copyText.gcpFormBillingLocationInputLabel}
                >
                  <Select
                    options={locationOptions}
                    value={locationOptions.find(
                      (option) => option.value === field.state.value
                    )}
                    onChange={(option) =>
                      option && field.handleChange(option.value)
                    }
                  />
                </FormField>
              );
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
