import Form, { FormField } from "@/ui-lib/components/Form";
import IconTimes from "@/ui-lib/icons/IconTimes";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faInfoCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { CustomMetricDataType } from "@ternary/api-lib/constants/enums";
import { ExportSourceConfig, LabelMap } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import { isEqual } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Input } from "../../../types";
import Checkbox from "../../../ui-lib/components/Checkbox";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Select from "../../../ui-lib/components/Select";
import TextInput from "../../../ui-lib/components/TextInput";
import { isCustomMetricDataType } from "../../../utils/typeGuards";
import {
  bigQueryLocations,
  BQ_FIELDS_REGEX,
  PROJECT_ID_REGEX,
} from "../../admin/integrations/components/GcpIntegrationForm/constants";
import copyText from "../copyText";

const BIG_QUERY_INPUT_TYPE = "BIG_QUERY_INPUT_TYPE";
const CSV_INPUT_TYPE = "CSV_INPUT_TYPE";

const BIG_QUERY_TABLE_ROW_LIMIT = 50_000;

type BqMetadata = { rowCount: number; schema: Record<string, string>[] };

export type CustomMetricEntity = {
  id: string;
  tenantID: string;
  bigQueryConfig: ExportSourceConfig | null;
  createdAt: string;
  createdByID: string;
  errorMessage: string | null;
  location: string | null;
  name: string;
  schema: Record<string, CustomMetricDataType | null>;
  status: string;
  updatedAt: string | null;
  updatedByID: string | null;
};

interface Props {
  customMetrics: CustomMetricEntity[];
  bigQueryMetadata?: BqMetadata;
  externalLabelMap?: LabelMap;
  isLoadingBigQueryMetadata: boolean;
  isLoadingCustomMetrics: boolean;
  selectedCustomMetric?: CustomMetricEntity;
  isProcessing: boolean;
  onInteraction: (interaction: CustomMetricForm.Interaction) => void;
}

interface State {
  bigQueryConfig: {
    bigQueryDatasetIDInput: Input<string | null>;
    bigQueryLocationInput: Input<string | null>;
    bigQueryProjectIDInput: Input<string | null>;
    bigQueryTableIDInput: Input<string | null>;
  };
  csvInput: Input<string | null>;
  inputType: string;
  nameInput: Input<string>;
  schemaInput: Input<Record<string, string | null>>;
}

const initialState: State = {
  bigQueryConfig: {
    bigQueryDatasetIDInput: {
      value: null,
      hasChanged: false,
      isValid: false,
    },
    bigQueryLocationInput: {
      value: null,
      hasChanged: false,
      isValid: false,
    },
    bigQueryProjectIDInput: {
      value: null,
      hasChanged: false,
      isValid: false,
    },
    bigQueryTableIDInput: {
      value: null,
      hasChanged: false,
      isValid: false,
    },
  },
  csvInput: { value: null, hasChanged: false, isValid: false },
  inputType: CSV_INPUT_TYPE,
  nameInput: { value: "", hasChanged: false, isValid: false },
  schemaInput: { value: {}, hasChanged: false, isValid: false },
};

export function CustomMetricForm(props: Props): JSX.Element {
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(
    props.selectedCustomMetric !== undefined
      ? {
          bigQueryConfig: {
            bigQueryDatasetIDInput: {
              value:
                props.selectedCustomMetric.bigQueryConfig?.datasetID ?? null,
              hasChanged: false,
              isValid: true,
            },
            bigQueryLocationInput: {
              value:
                props.selectedCustomMetric.bigQueryConfig?.location ?? null,
              hasChanged: false,
              isValid: true,
            },
            bigQueryProjectIDInput: {
              value:
                props.selectedCustomMetric.bigQueryConfig?.projectID ?? null,
              hasChanged: false,
              isValid: true,
            },
            bigQueryTableIDInput: {
              value: props.selectedCustomMetric.bigQueryConfig?.tableID ?? null,
              hasChanged: false,
              isValid: true,
            },
          },
          csvInput: {
            value: null,
            hasChanged: false,
            isValid: true,
          },
          inputType: props.selectedCustomMetric.bigQueryConfig
            ? BIG_QUERY_INPUT_TYPE
            : CSV_INPUT_TYPE,
          nameInput: {
            value: props.selectedCustomMetric.name,
            hasChanged: false,
            isValid: true,
          },
          schemaInput: {
            value: props.selectedCustomMetric.schema,
            hasChanged: false,
            isValid: true,
          },
        }
      : initialState
  );

  const mergeState = getMergeState(setState);

  //
  // Effects
  //

  useEffect(() => {
    if (!props.bigQueryMetadata) return;

    mergeState({
      csvInput: { value: null, hasChanged: true, isValid: true },
      schemaInput: {
        value: props.bigQueryMetadata.schema.reduce((accum, value) => {
          return {
            ...accum,
            [value.name]: "",
          };
        }, {}),
        isValid: false,
        hasChanged: true,
      },
    });
  }, [props.bigQueryMetadata]);

  //
  // Interaction Handlers
  //

  function handleCancel(): void {
    props.onInteraction({
      type: CustomMetricForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });

    setState(initialState);
  }

  function handleChangeBigQueryConfigInput(
    event: ChangeEvent<HTMLInputElement>
  ) {
    const { name, value } = event.target;

    let isValid = false;
    let hasChanged = false;

    switch (name) {
      case "bigQueryProjectID": {
        isValid = PROJECT_ID_REGEX.test(value);
        hasChanged =
          props.selectedCustomMetric?.bigQueryConfig?.projectID !== value;
        break;
      }
      case "bigQueryDatasetID": {
        isValid = BQ_FIELDS_REGEX.test(value);
        hasChanged =
          props.selectedCustomMetric?.bigQueryConfig?.datasetID !== value;
        break;
      }
      case "bigQueryTableID": {
        isValid = BQ_FIELDS_REGEX.test(value);
        hasChanged =
          props.selectedCustomMetric?.bigQueryConfig?.tableID !== value;
        break;
      }
      case "bigQueryLocation": {
        isValid = true;
        hasChanged =
          props.selectedCustomMetric?.bigQueryConfig?.location !== value;
        break;
      }
    }

    setState((currentState) => ({
      ...currentState,
      bigQueryConfig: {
        ...currentState.bigQueryConfig,
        [`${name}Input`]: { value, isValid, hasChanged },
      },
    }));
  }

  function handleChangeName(value: string): void {
    const name = value.replace(/\s/g, "");

    const isValid = name.length > 0;

    const hasChanged = !isEqual(name, props.selectedCustomMetric?.name);

    mergeState({
      nameInput: { value: name, hasChanged, isValid },
    });
  }

  async function handleFileUpload(file: Blob): Promise<void> {
    const blobString = await file.text();

    const base64CSV = Buffer.from(blobString).toString("base64");

    const csvHeaders = blobString.split("\r\n")[0].split(",");

    mergeState({
      csvInput: { value: base64CSV, hasChanged: true, isValid: true },
      schemaInput: {
        value: csvHeaders.reduce((accum, header) => {
          if (accum[header]) return accum;

          accum[header] = "";

          return accum;
        }, {}),
        hasChanged: true,
        isValid: false,
      },
    });
  }

  function handleSelectSchemaOption(header: string, value: string): void {
    setState((currentState) => {
      const schemaValue = {
        ...currentState.schemaInput.value,
        [header]: value,
      };

      const [hasChanged, isValid] = validateSchema({
        externalLabelMap: props.externalLabelMap,
        schema: schemaValue,
        selectedCustomMetric: props.selectedCustomMetric,
      });

      return {
        ...currentState,
        schemaInput: {
          value: schemaValue,
          hasChanged,
          isValid,
        },
      };
    });
  }

  function handleSubmit(): void {
    const isCsvInput = state.inputType === CSV_INPUT_TYPE;

    if (props.selectedCustomMetric) {
      props.onInteraction({
        type: CustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        ...(hasChangedBigQueryConfig && !isCsvInput
          ? {
              bigQueryConfig: {
                datasetID:
                  state.bigQueryConfig.bigQueryDatasetIDInput.value ?? "",
                location:
                  state.bigQueryConfig.bigQueryLocationInput.value ?? "",
                projectID:
                  state.bigQueryConfig.bigQueryProjectIDInput.value ?? "",
                tableID: state.bigQueryConfig.bigQueryTableIDInput.value ?? "",
              },
            }
          : {}),
        ...(state.csvInput.hasChanged && isCsvInput
          ? { csv: state.csvInput.value }
          : {}),
        ...(state.schemaInput.hasChanged
          ? { schema: getSchemaFromState(state.schemaInput.value) }
          : {}),
        ...(state.nameInput.hasChanged ? { name: state.nameInput.value } : {}),
      });
    } else {
      props.onInteraction({
        type: CustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        bigQueryConfig:
          isValidBigQueryConfig && !isCsvInput
            ? {
                datasetID:
                  state.bigQueryConfig.bigQueryDatasetIDInput.value ?? "",
                location:
                  state.bigQueryConfig.bigQueryLocationInput.value ?? "",
                projectID:
                  state.bigQueryConfig.bigQueryProjectIDInput.value ?? "",
                tableID: state.bigQueryConfig.bigQueryTableIDInput.value ?? "",
              }
            : null,
        csv: state.csvInput.value,
        schema: getSchemaFromState(state.schemaInput.value),
        name: state.nameInput.value,
      });
    }
  }

  function handleToggleCheckbox(checked: boolean, headerKey: string) {
    setState((currentState) => {
      const schemaValue = {
        ...currentState.schemaInput.value,
        [headerKey]: checked ? "" : null,
      };

      const [hasChanged, isValid] = validateSchema({
        externalLabelMap: props.externalLabelMap,
        schema: schemaValue,
        selectedCustomMetric: props.selectedCustomMetric,
      });

      return {
        ...currentState,
        schemaInput: {
          ...currentState.schemaInput,
          hasChanged,
          isValid,
          value: schemaValue,
        },
      };
    });
  }

  function validateBigQuerySchema(): void {
    if (!isValidBigQueryConfig) {
      return;
    }

    props.onInteraction({
      type: CustomMetricForm.INTERACTION_VALIDATE_BUTTON_CLICKED_UPDATE,
      bigQueryConfig: {
        datasetID: state.bigQueryConfig.bigQueryDatasetIDInput.value ?? "",
        location: state.bigQueryConfig.bigQueryLocationInput.value ?? "",
        projectID: state.bigQueryConfig.bigQueryProjectIDInput.value ?? "",
        tableID: state.bigQueryConfig.bigQueryTableIDInput.value ?? "",
      },
    });
  }

  //
  // Render
  //

  const csvSchemaOptions = [
    {
      label: copyText.customMetricFormSchemaDimensionLabel,
      value: CustomMetricDataType.DIMENSION,
    },
    {
      label: copyText.customMetricFormSchemaMeasureLabel,
      value: CustomMetricDataType.MEASURE,
    },
    {
      label: copyText.customMetricFormSchemaTimestampLabel,
      value: CustomMetricDataType.TIMESTAMP,
    },
  ];

  const hasChanged = Object.values(state).some((input) => input.hasChanged);

  const hasChangedBigQueryConfig =
    state.bigQueryConfig.bigQueryDatasetIDInput.hasChanged &&
    state.bigQueryConfig.bigQueryLocationInput.hasChanged &&
    state.bigQueryConfig.bigQueryProjectIDInput.hasChanged &&
    state.bigQueryConfig.bigQueryTableIDInput.hasChanged;

  const isNameInputValid =
    !!props.selectedCustomMetric ||
    !props.customMetrics.some(
      (metric) =>
        metric.name.toLowerCase() === state.nameInput.value.toLowerCase()
    );

  const isValid = Object.entries(state).every(([key, input]) => {
    if (key === "inputType") {
      return true;
    }

    if (key === "bigQueryConfig") {
      if (state.inputType === BIG_QUERY_INPUT_TYPE) {
        return Object.values(input).every((input) => (input as Input).isValid);
      } else {
        return true;
      }
    }

    return input.isValid;
  });

  const isValidBigQueryConfig =
    state.bigQueryConfig.bigQueryDatasetIDInput.isValid &&
    state.bigQueryConfig.bigQueryLocationInput.isValid &&
    state.bigQueryConfig.bigQueryProjectIDInput.isValid &&
    state.bigQueryConfig.bigQueryTableIDInput.isValid;

  const canSubmit = props.selectedCustomMetric
    ? hasChanged && isValid
    : isValid;

  const headerEntries = Object.entries(state.schemaInput.value);

  const bigQueryLocationOptions = bigQueryLocations.map((location) => ({
    label: location,
    value: location,
  }));

  const typeOptions = [
    {
      label: copyText.customMetricFormCsvInputTypeLabel,
      value: CSV_INPUT_TYPE,
    },
    {
      label: copyText.customMetricFormBigQueryInputTypeLabel,
      value: BIG_QUERY_INPUT_TYPE,
    },
  ];

  const selectedTypeOption = typeOptions.find(
    (option) => option.value === state.inputType
  );

  const bqTableRowCountExceeded =
    props.bigQueryMetadata &&
    props.bigQueryMetadata.rowCount > BIG_QUERY_TABLE_ROW_LIMIT;

  return (
    <Form>
      <Flex alignItems="center">
        {/* Name Input */}
        <Box width="50%">
          <Tooltip
            content={copyText.customMetricFormInvalidNameInputTooltipLabel}
            hide={isNameInputValid}
            placement="auto"
            width="250px"
          >
            <FormField
              input={TextInput}
              label={
                <Flex alignItems="center">
                  <Text>{copyText.nameInputLabel}</Text>
                  <Tooltip
                    icon={faInfoCircle}
                    content={copyText.customMetricFormNameInputTooltipLabel}
                    placement="right"
                    width="250px"
                  />
                </Flex>
              }
              value={state.nameInput.value}
              variant={isNameInputValid ? undefined : "danger"}
              onChange={(event) => handleChangeName(event.target.value)}
            />
          </Tooltip>
        </Box>
        <Box paddingLeft={theme.space_md} width="50%">
          <FormField label={copyText.customMetricFormTypeInputLabel}>
            <Select
              options={typeOptions}
              value={selectedTypeOption}
              onChange={(option) =>
                mergeState({
                  inputType: option?.value,
                  schemaInput: {
                    value: {},
                    hasChanged: true,
                    isValid: false,
                  },
                })
              }
            />
          </FormField>
        </Box>
      </Flex>

      {/* BigQuery Input*/}
      {state.inputType === BIG_QUERY_INPUT_TYPE && (
        <Box marginBottom={theme.space_sm}>
          <FormField
            input={TextInput}
            name="bigQueryProjectID"
            label={copyText.customMetricFormBigQueryProjectIDLabel}
            value={
              state.bigQueryConfig.bigQueryProjectIDInput.value ?? undefined
            }
            variant={
              state.bigQueryConfig.bigQueryProjectIDInput.isValid
                ? "success"
                : "danger"
            }
            required
            onChange={(event) => handleChangeBigQueryConfigInput(event)}
          />
          <FormField
            input={TextInput}
            name="bigQueryDatasetID"
            label={copyText.customMetricFormBigQueryDatasetIDLabel}
            value={
              state.bigQueryConfig.bigQueryDatasetIDInput.value ?? undefined
            }
            variant={
              state.bigQueryConfig.bigQueryDatasetIDInput.isValid
                ? "success"
                : "danger"
            }
            required
            onChange={(event) => handleChangeBigQueryConfigInput(event)}
          />
          <FormField
            input={TextInput}
            name="bigQueryTableID"
            label={copyText.customMetricFormBigQueryTableIDLabel}
            value={state.bigQueryConfig.bigQueryTableIDInput.value ?? undefined}
            variant={
              state.bigQueryConfig.bigQueryTableIDInput.isValid
                ? "success"
                : "danger"
            }
            required
            onChange={(event) => handleChangeBigQueryConfigInput(event)}
          />
          <FormField
            label={copyText.customMetricFormBigQueryLocationLabel}
            required
          >
            <Select
              value={bigQueryLocationOptions.find(
                (option) =>
                  option.value ===
                  state.bigQueryConfig.bigQueryLocationInput.value
              )}
              options={bigQueryLocationOptions}
              onChange={(option) =>
                option &&
                handleChangeBigQueryConfigInput({
                  target: { name: "bigQueryLocation", value: option.value },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
          </FormField>
          <Flex alignItems="center">
            <Button
              disabled={!isValidBigQueryConfig || props.isLoadingCustomMetrics}
              marginRight={theme.space_sm}
              secondary
              size="small"
              width={100}
              type="button"
              onClick={validateBigQuerySchema}
            >
              {props.isLoadingBigQueryMetadata ? (
                <LoadingSpinner />
              ) : (
                copyText.customMetricFormValidateLabel
              )}
            </Button>
            {bqTableRowCountExceeded ? (
              <Tooltip
                content={
                  copyText.customMetricFormBigQueryTableLimitExceededMessage
                }
                width="250px"
              >
                <Icon
                  color={theme.tag_button_background_color_danger_hover}
                  icon={faWarning}
                />
              </Tooltip>
            ) : null}
          </Flex>
        </Box>
      )}

      {/* CSV Input */}
      {state.inputType === CSV_INPUT_TYPE && (
        <Flex alignItems="center">
          <FormField label={copyText.customMetricFormCsvUploadLabel}>
            <Box minWidth={350}>
              <FileUploader
                handleChange={(file) => handleFileUpload(file)}
                multiple={false}
                types={["CSV"]}
              />
            </Box>
          </FormField>
          <Tooltip
            icon={faInfoCircle}
            content={copyText.customMetricFormCsvDocumentationLabel}
          />
        </Flex>
      )}

      {/* Schema Input */}

      {Object.keys(state.schemaInput.value).length > 0 && (
        <FormField label={copyText.customMetricFormDataTypeInputLabel} required>
          <Box marginTop={theme.space_sm}>
            {headerEntries.map(([headerKey, headerValue]) => {
              const isValidDimension =
                headerValue === CustomMetricDataType.DIMENSION
                  ? !Object.keys(props.externalLabelMap ?? {}).some(
                      (label) => label === headerKey
                    ) ||
                    (props.selectedCustomMetric !== undefined &&
                      Object.entries(props.selectedCustomMetric.schema).some(
                        ([key, value]) =>
                          headerKey === key &&
                          value === CustomMetricDataType.DIMENSION
                      ))
                  : true;

              return (
                <Flex
                  key={`${headerKey}-${headerValue}`}
                  justifyContent="space-between"
                  marginBottom={theme.space_md}
                >
                  <Checkbox
                    checked={state.schemaInput.value[headerKey] !== null}
                    onChange={(event) =>
                      handleToggleCheckbox(event.target.checked, headerKey)
                    }
                  />
                  <Box paddingLeft={theme.space_md} width="50%">
                    <Tooltip
                      content={
                        copyText.customMetricFormInvalidDimensionInputTooltipLabel
                      }
                      hide={isValidDimension}
                      width="200px"
                    >
                      <TextInput
                        disabled
                        value={headerKey}
                        variant={isValidDimension ? undefined : "danger"}
                      />
                    </Tooltip>
                  </Box>

                  <Flex
                    alignItems="center"
                    paddingLeft={theme.space_md}
                    width="50%"
                  >
                    <Select
                      disabled={state.schemaInput.value[headerKey] === null}
                      options={csvSchemaOptions.filter(
                        (option) =>
                          !Object.values(state.schemaInput.value).some(
                            (value) =>
                              value !== CustomMetricDataType.DIMENSION &&
                              option.value === value
                          )
                      )}
                      placeholder={
                        copyText.customMetricFormSchemaSelectPlaceholder
                      }
                      value={csvSchemaOptions.find(
                        (option) => option.value === headerValue
                      )}
                      onChange={(option) =>
                        option &&
                        handleSelectSchemaOption(headerKey, option.value)
                      }
                    />
                    <Button
                      disabled={
                        state.schemaInput.value[headerKey] === undefined
                      }
                      iconStart={<IconTimes />}
                      size="tiny"
                      type="button"
                      onClick={() => handleSelectSchemaOption(headerKey, "")}
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Box>
        </FormField>
      )}

      <Flex justifyContent="flex-end" marginTop={theme.space_sm}>
        <Button secondary type="reset" width={100} onClick={handleCancel}>
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isProcessing || bqTableRowCountExceeded}
          marginLeft={theme.space_sm}
          primary
          width={100}
          type="button"
          onClick={handleSubmit}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

function getSchemaFromState(
  schema: Record<string, string | null>
): Record<string, CustomMetricDataType | null> {
  return Object.entries(schema).reduce(
    (accum, [key, value]) => ({
      ...accum,
      [key]: value && isCustomMetricDataType(value) ? value : null,
    }),
    {}
  );
}

function validateSchema(params: {
  externalLabelMap?: LabelMap;
  schema: Record<string, CustomMetricDataType | string | null>;
  selectedCustomMetric?: CustomMetricEntity;
}) {
  const schemaEntries = Object.entries(params.schema);

  const isValid =
    schemaEntries.every(([_, value]) =>
      value !== null ? isCustomMetricDataType(value) : true
    ) &&
    schemaEntries.every(([key, value]) =>
      value === CustomMetricDataType.DIMENSION
        ? !Object.keys(params.externalLabelMap ?? {}).some(
            (label) => label === key
          )
        : true
    ) &&
    schemaEntries.some(
      ([_, value]) => value === CustomMetricDataType.MEASURE
    ) &&
    schemaEntries.some(
      ([_, value]) => value === CustomMetricDataType.TIMESTAMP
    );

  const hasChanged = !isEqual(
    params.schema,
    params.selectedCustomMetric?.schema
  );

  return [hasChanged, isValid];
}

CustomMetricForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "CreateCustomMetricForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;

CustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  "CreateCustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE" as const;

CustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  "CreateCustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE" as const;

CustomMetricForm.INTERACTION_VALIDATE_BUTTON_CLICKED_UPDATE =
  "CreateCustomMetricForm.INTERACTION_VALIDATE_BUTTON_CLICKED_UPDATE" as const;

type InteractionCancelButtonClicked = {
  type: typeof CustomMetricForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClickedCreate = {
  type: typeof CustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  bigQueryConfig: ExportSourceConfig | null;
  csv: string | null;
  name: string;
  schema: Record<string, CustomMetricDataType | null>;
};

type InteractionSubmitButtonClickedUpdate = {
  type: typeof CustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  csv?: string | null;
  name?: string;
  schema?: Record<string, CustomMetricDataType | null>;
};

type InteractionValidateButtonClicked = {
  type: typeof CustomMetricForm.INTERACTION_VALIDATE_BUTTON_CLICKED_UPDATE;
  bigQueryConfig: ExportSourceConfig;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CustomMetricForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate
    | InteractionValidateButtonClicked;
}

export default CustomMetricForm;
