import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import { GrantEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import { convertSnakeCaseToTitleCase } from "@ternary/api-lib/utils/CaseUtils";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours } from "date-fns";
import { keyBy, uniq } from "lodash";
import React, { useState } from "react";
import { ArrayParam, useQueryParams, withDefault } from "use-query-params";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import { updateAuthenticatedUserGrants } from "../../../utils/QueryClientUtils";
import { AlertType, postAlert } from "../../../utils/alerts";
import { getVendorFromCloudProviderType } from "../../../utils/cloudProviderConverter";
import getMergeState from "../../../utils/getMergeState";
import useGrantUsersTenantAccess from "../../admin/hooks/useGrantUsersTenantAccess";
import useGetRoles from "../../global-admin/hooks/useGetRoles";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import { StatusVariant } from "../constants";
import copyText from "../copyText";
import useGetMspChildDataIntegrationsByParentTenantID from "../hooks/useGetMspChildDataIntegrations";
import { parseArrayParam } from "./MspDashboardPage";
import MspDataIntegrationTable from "./MspDataIntegrationTable";
type Interaction = MspDataIntegrationTable.Interaction;

type State = {
  providerOptions: string[];
  selectedTenantID: string | null;
  showModal: boolean;
  statusOptions: string[];
};

const initialState: State = {
  providerOptions: [],
  selectedTenantID: null,
  showModal: false,
  statusOptions: [],
};

const statusOptions = Object.values(StatusVariant).map((value) => ({
  label: copyText[`statusVariantLabel_${value}`],
  value,
}));

interface Props {
  parentTenantID: string;
}

export default function MspDataIntegrationViewContainer(props: Props) {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const [searchParamState] = useQueryParams({
    t_ids: withDefault(ArrayParam, []),
  });

  const tenantDocIDs = parseArrayParam(searchParamState.t_ids);

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingTenants } =
    useGetTenantsByParentTenantID(props.parentTenantID, {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const { data: _integrations = [], isLoading: isLoadingIntegrations } =
    useGetMspChildDataIntegrationsByParentTenantID(props.parentTenantID);

  const { data: roles = [] } = useGetRoles();

  //
  // Mutations
  //

  const {
    isPending: isGrantingUserTenantAccess,
    mutate: grantUsersTenantAccess,
  } = useGrantUsersTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUserTenantAccessMessage,
      });
    },
    onSuccess: (_, params) => {
      updateAuthenticatedUserGrants(queryClient, params);

      window.open(
        `${window.location.origin}/?tenant_id=${params.tenantID}`,
        "_blank"
      );

      mergeState({ selectedTenantID: null, showModal: false });
    },
  });

  //
  // InteractionHandlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case MspDataIntegrationTable.INTERACTION_LINK_CLICKED: {
        const tenant = tenantsKeyedByDocID[interaction.tenantDocID];

        mergeState({
          selectedTenantID: tenant.id,
          showModal: true,
        });
        return;
      }
    }
  }

  function handleSubmitTenantAccessModal() {
    if (!state.selectedTenantID) return;

    const rolesKeyedByName = keyBy(roles, "name");

    const globalGrant = authenticatedUser.grants.find(
      (grant) => grant.tenantDocID === SYSTEM_TENANT_ID
    ) as GrantEntity; // Always guaranteed to exist here.

    const roleNames = globalGrant.roles.map((role) =>
      convertSnakeCaseToTitleCase(role)
    );

    const roleIDs = roleNames.map((roleName) => rolesKeyedByName[roleName].id);

    grantUsersTenantAccess({
      tenantID: state.selectedTenantID,
      users: [
        {
          userID: authenticatedUser.id,
          email: authenticatedUser.email,
          roleIDs,
        },
      ],
    });
  }

  //
  // Render
  //

  const isLoading = isLoadingIntegrations || isLoadingTenants;

  const datehelper = new DateHelper();

  const tenantsKeyedByDocID = keyBy(_mspTenants, "fsDocID");

  let integrations = _integrations.map((integration) => {
    const absoluteRefresh = integration.latestRefresh
      ? new Date(integration.latestRefresh)
      : null;

    const absoluteUpstream = integration.latestUpstreamTimestamp
      ? new Date(integration.latestUpstreamTimestamp)
      : null;

    const refreshDifference = differenceInHours(
      datehelper.date,
      absoluteRefresh ?? 0
    );

    const upstreamDifference = differenceInHours(
      datehelper.date,
      absoluteUpstream ?? 0
    );

    let statusVariant: StatusVariant = StatusVariant.DANGER;

    if (refreshDifference <= 8 && upstreamDifference <= 24) {
      statusVariant = StatusVariant.SUCCESS;
    } else if (refreshDifference <= 24 && upstreamDifference <= 72) {
      statusVariant = StatusVariant.WARNING;
    }

    return {
      tenantDocID: integration.tenantDocID,
      latestRefresh: integration.latestRefresh,
      latestUpstream: integration.latestUpstreamTimestamp,
      name: integration.name,
      providerType: integration.providerType,
      refreshDifference,
      statusVariant,
      tenantName: tenantsKeyedByDocID[integration.tenantDocID]?.name ?? "null",
    };
  });

  const providerOptions = uniq(
    _integrations.map((integration) => integration.providerType)
  )
    .sort()
    .map((provider) => {
      return {
        label: getVendorFromCloudProviderType(provider),
        value: provider,
      };
    });

  if (state.statusOptions.length > 0) {
    integrations = integrations.filter((integration) =>
      state.statusOptions.includes(integration.statusVariant)
    );
  }

  if (state.providerOptions.length > 0) {
    integrations = integrations.filter((integration) =>
      state.providerOptions.includes(integration.providerType)
    );
  }

  const filteredIntegrations =
    tenantDocIDs.length > 0
      ? integrations.filter((integration) =>
          tenantDocIDs.includes(integration.tenantDocID)
        )
      : integrations;

  return (
    <Box>
      {state.showModal && (
        <ConfirmationModal
          isLoading={isGrantingUserTenantAccess}
          message={copyText.tenantAccessModalMessage}
          title={copyText.tenantAccessModalTitle}
          onCancel={() => mergeState({ showModal: false })}
          onConfirm={handleSubmitTenantAccessModal}
        />
      )}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_sm}
      >
        <Text appearance="h3">{copyText.sectionHeaderCloudDataStatus}</Text>
        <Flex>
          <Flex>
            <SelectCheckbox
              options={providerOptions}
              placeholder={copyText.inputPlaceholderFilterProvider}
              selectedValues={state.providerOptions}
              width={250}
              onChange={(value) => {
                mergeState({ providerOptions: value.sort() });

                activityTracker.captureAction(
                  actions.MSP_DASHBOARD_CLOUD_PROVIDER_FILTER,
                  { provider: value }
                );
              }}
            />
          </Flex>
          <Flex marginLeft={theme.space_sm}>
            <SelectCheckbox
              options={statusOptions}
              placeholder={copyText.inputPlaceholderFilterStatus}
              selectedValues={state.statusOptions}
              width={250}
              onChange={(value) => {
                mergeState({ statusOptions: value.sort() });

                activityTracker.captureAction(
                  actions.MSP_DASHBOARD_CLOUD_STATUS_FILTER,
                  { status: value }
                );
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      <MspDataIntegrationTable
        integrations={filteredIntegrations}
        isLoading={isLoading}
        onInteraction={handleInteraction}
      />
    </Box>
  );
}
