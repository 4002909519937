import { useTheme } from "@emotion/react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  FormOptions,
  ReactFormExtendedApi,
  useForm,
} from "@tanstack/react-form";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { ReactNode, useState } from "react";
import copyText from "../copyText";
import Form from "./Form";

export type Step<TFormData> = {
  component: (form: ReactFormExtendedApi<TFormData>) => ReactNode;
  isOptional?: boolean;
  label: string;
  requiredInputs?: string[];
};

interface Props<TFormData> extends FormOptions<TFormData> {
  steps: Step<TFormData>[];
}

export function VerticalStepperForm<TFormData>(props: Props<TFormData>) {
  const theme = useTheme();

  const { steps, ...formOptions } = props;

  const form = useForm<TFormData>(formOptions);

  //   console.log("FORM >>>", form);

  const [currentStep, setCurrentStep] = useState(0);

  const goToNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Form
      flex
      width="55%"
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        form.handleSubmit();
      }}
    >
      <Box flex="1 0 0" overflowY="auto">
        {steps.map((step, index) => {
          return (
            <Box key={index} marginBottom={theme.space_sm}>
              <Flex alignItems="center" marginBottom={theme.space_sm}>
                <Flex
                  alignItems="center"
                  backgroundColor={
                    index <= currentStep
                      ? theme.primary_color_background
                      : theme.secondary_color_background
                  }
                  borderRadius="50%"
                  height={32}
                  justifyContent="center"
                  marginRight={theme.space_md}
                  width={32}
                >
                  <Text color="white">
                    {currentStep > index ? (
                      <Icon icon={faCheck}></Icon>
                    ) : (
                      index + 1
                    )}
                  </Text>
                </Flex>
                <Box>
                  <Text fontSize={theme.fontSize_base}>{step.label}</Text>
                  {step.isOptional ? (
                    <Text
                      color={theme.text_color_secondary}
                      fontSize={theme.h6_fontSize}
                    >
                      {copyText.optionalStepCaption}
                    </Text>
                  ) : null}
                </Box>
              </Flex>
              <Flex>
                <Box
                  backgroundColor="gray"
                  marginHorizontal={theme.space_md}
                  minHeight={index === steps.length - 1 ? 0 : 16}
                  width={1}
                />
                {index === currentStep ? (
                  <Box paddingLeft={theme.space_sm}>
                    <Box>{step.component(form)}</Box>
                    <Flex
                      gap="16px"
                      marginBottom={theme.space_lg}
                      marginTop={theme.space_md}
                    >
                      {currentStep > 0 && (
                        <Button
                          secondary
                          type="button"
                          width={100}
                          onClick={goToPreviousStep}
                        >
                          {copyText.previousButtonLabel}
                        </Button>
                      )}
                      {currentStep < steps.length - 1 && (
                        <form.Subscribe
                          children={(state) => {
                            const disabled =
                              step.requiredInputs &&
                              step.requiredInputs.some(
                                (input) =>
                                  (state.values[input] &&
                                    state.values[input].length === 0) ||
                                  (state.fieldMeta[input] &&
                                    state.fieldMeta[input].errors.length > 0)
                              );

                            return (
                              <Button
                                disabled={disabled}
                                secondary
                                type="button"
                                width={100}
                                onClick={goToNextStep}
                              >
                                {copyText.nextButtonLabel}
                              </Button>
                            );
                          }}
                        />
                      )}
                    </Flex>
                  </Box>
                ) : null}
              </Flex>
            </Box>
          );
        })}
      </Box>
    </Form>
  );
}
